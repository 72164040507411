import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });
  return res.data;
};

export const poster = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.post(url, { ...config });
  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  reservation: {
    root: '/api/reservation',
    image: '/api/upload/reservation/image',
  },
  notification: '/api/notification',
  settingReservation: '/api/default-setting',
  device: {
    root: '/api/device',
    checkout: '/api/device/checkout',
  },
  cakeOffer: '/api/cake-offer',
  gallery: '/api/gallery',
  galleryTag: '/api/gallery/tag',
  galleryUpload: '/api/upload/gallery/image',
  uploadImage: '/api/upload/image',
  workRecord: '/api/work-record',
  inventory: '/api/inventory',
  feedback: '/api/feedback',
  slots: '/api/slots',
  dayNote: '/api/day-note',
  export: '/api/exports',
  config: {
    get: '/api/public/config',
    update: '/api/config',
  },
  auth: {
    me: '/api/me',
    login: '/api/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
